import React from 'react'
import BlogCard from '../components/BlogCard'



const BlogCardList = (props) => {



    return (


        <div style={{ backgroundColor: '#18191a' }} className=' grid justify-items-center  sm:grid-cols-3 grid-rows-2 gap-2 rounded-xl p-3 mt-3'>


            {props.blogs.map((blog) => (

                <div key={blog.blog_Title}>
                    <BlogCard Title={blog.blog_Title}
                        Image={blog.blog_Images[0].original}
                        Description={blog.blog_description}
                        tags={blog.tags}
                        activity={blog.activity}
                        live={blog.live}
                        url_prefix={blog.url_prefix}
                    />


                </div>
            ))}



        </div>

    )

}

export default BlogCardList