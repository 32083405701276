import React, { useState, useEffect } from 'react'

import ReactPlayer from 'react-player'
import { ShareModule } from '../components/ShareModule'
import VisitLogging from '../utils/VisitLogging'
import ImageGallery from 'react-image-gallery';
import $ from "jquery";
import { Image, Placeholder, Video, Transformation, CloudinaryContext } from 'cloudinary-react';
import sfw from '../assets/images/design/sfw.png'
import lw from '../assets/images/design/lw.png'
import blend from '../assets/images/design/blend.png'
import mar from '../assets/images/design/mar.png'
import ps from '../assets/images/design/ps.png'
import ai from '../assets/images/design/ai.png'
import vss from '../assets/images/design/vss.jpg'
import springer from '../assets/images/design/springer.png'
import bav from '../assets/images/design/bav.png'
import a10 from '../assets/images/design/a10.png'
import { useLocation } from "react-router-dom"


const DesignPage = () => {


    const [fullSize, setFullSize] = useState(true)
    const [fullSize2, setFullSize2] = useState(true)
    const [fullSize3, setFullSize3] = useState(true)
    const location=useLocation()


    const loadItems = () => {
        return new Promise(resolve => setTimeout(resolve, 500)) // 2 seconds
    }

    useEffect(() => {
        const ele = document.getElementById('ipl-progress-indicator')
        console.log("component_mounted", ele)


        loadItems().then(() => {
            const ele = document.getElementById('ipl-progress-indicator')
            const ele2 = document.getElementById('container')
            if (ele) {
                // fade out
                ele.classList.add('available')
                ele2.classList.add('available')
                setTimeout(() => {
                    // remove from DOM
                    ele.outerHTML = ''
                    ele2.outerHTML = ''
                }, 500)
            }
        })






    })











    const handleLoad = (e) => {

        VisitLogging("DesignPage")


      /*  $(document).ready(function () {
            $(function () {
                $('html, body').animate({
                    scrollTop: $("#DesignPageContent").offset().top - 200
                }, 1000);
            });
        });*/



    }


    return (
        <div id="motion" className='text-gray-300 pt-28 ' style={{ backgroundColor: '#2a2a2a' }}>




            <Image
                id='DesignPageContent'
                onLoad={(e) => handleLoad(e)}
                publicId="attilawolf/header_osmz9r.png"
                cloud_name="attilawolf"
                loading="lazy">
                <Transformation quality="52" />
                <Placeholder
                    type="vectorize">
                </Placeholder>
            </Image>


            {/* <img id='DesignPageContent' onLoad={(e) => handleLoad(e)} alt='colorful dust' src={header} className=''></img>*/}



            <div className='grid justify-items-center sm:flex  rounded-lg px-6 pt-3 pb-10 mb-3   ' style={{ backgroundColor: "#18191a" }}>


                <div  className='mx-3 w-full  sm:w-1/2' >

                    <label className='text-2xl text-gray-300 font-semibold'>MOTION GRAPHICS</label>
                    <article className='text-sm font-thin mt-3'>

                        <p>In my free time, I enjoy designing motion graphics and 3D objects. </p>
                        <p>Sometimes, I also give a scientific spin to my creations:</p>

                        <div className='my-2'>
                            <div onClick={() => window.open("http://illusionoftheyear.com/2013/05/rotating-by-scaling/", "_blank")}
                                className=' flex items-center mb-1 group'>

                                <img alt='vss logo' src={vss} className='w-8 h-8 cursor-pointer group-hover:opacity-80'></img>
                                <p className='cursor-pointer pl-1 group-hover:underline'> Best Illusion of the Year Contest by VSS (2013)</p>

                            </div>


                            <div onClick={() => window.open("https://link.springer.com/article/10.1007/s00371-015-1152-5", "_blank")} className=' group flex items-center'>
                                <img alt='springer link logo' src={springer} className='w-8 h-8 cursor-pointer group-hover:opacity-80'></img>
                                <p className='cursor-pointer pl-1 group-hover:underline'> Dynamic 3-D computer graphics in clinical diagnostics</p>
                            </div>

                        </div>
                        <div>My favorite tools in the house: LightWave and Blender for 3D modeling and animating, Marvelous designer and the ususal pixel and vectorgraph kings.
                        <span className='flex my-2'>

                                <img onClick={() => window.open("https://www.lightwave3d.com/", "_blank")} alt='lightwave' src={lw} className='w-8 h-8 cursor-pointer hover:opacity-80'></img>
                                <img onClick={() => window.open("https://www.blender.org/", "_blank")} alt='blender icon' src={blend} className='w-8 h-8 cursor-pointer hover:opacity-80'></img>
                                <img onClick={() => window.open("https://marvelousdesigner.com/", "_blank")} alt='marvelous designer icon' src={mar} className='w-8 h-8 cursor-pointer hover:opacity-80 cursor-pointer hover:opacity-80'></img>
                                <img onClick={() => window.open("https://www.adobe.com/", "_blank")} alt='photoshop icon' src={ps} className='w-8 h-8 cursor-pointer hover:opacity-80' ></img>
                                <img onClick={() => window.open("https://www.adobe.com/", "_blank")} alt='illustrator icon' src={ai} className='w-8 h-8 cursor-pointer hover:opacity-80'></img>



                            </span>

                        </div>


                        <p>In case you liked any of these projects, why not give it a share?</p>

                    </article>


                    <ShareModule size={32} url={`https://attilawolf.info${location.pathname}`} shareData={`Hi, check out this 3D design project: https://attilawolf.info${location.pathname}`} />

                </div>


                <div className='relative w-full   h-64 sm:w-1/2'>


                    <label className='text-2xl mb-3  sm:mb-0 tex-gray-400 font-semibold'>DEMO REEL</label>




                    <ReactPlayer
                        className='absolute top-0-  left-0 '
                        url='https://attilawolf.info/video/demo_short.mp4'
                        controls={true}
                        width='auto'
                        height='250px'
                    />

                </div>


            </div>

            {/*======================================== FASHION WEEK=================================================*/}

            <div className='grid justify-items-center sm:flex  rounded-lg px-6 pt-3 pb-10 mb-3   ' style={{ backgroundColor: "#18191a" }}>


                <div className='mx-3 w-full  sm:w-1/2' >

                    <label className='text-2xl text-gray-400 font-semibold'>SFW 2020</label>
                    <article className='text-sm font-thin mt-3'>


                        <div onClick={() => window.open("https://www.sustainablefashionweek.us/watchsfw2020/", "_blank")}
                            className=' sm:flex items-center mb-1 group'>

                            <img alt='Fashin week logo' src={sfw} className=' mb-3 w-36 h-36 cursor-pointer group-hover:opacity-80'></img>
                            <p className=' mb-3 cursor-pointer pl-1 group-hover:underline'> Video promo with 3D animated garments for the Sustainable Fashion Week in Brooklyn 2020</p>

                        </div>


                        <div onClick={() => window.open("https://www.born-againvintage.com/", "_blank")}
                            className=' items-center mb-1 group'>
                            <p className='cursor-pointer pl-1 group-hover:underline'> Garment animation created based on a single image provided by:</p>
                            <img alt='Born again vintage logo' src={bav} className='w-18 h-8 cursor-pointer group-hover:opacity-80'></img>


                        </div>






                        {/* <p className=' text-gray-500 pl-2 mt-3 text-xs font-thin'>Share this video</p> */}

                    </article>


                    {/* <ShareModule size={32} /> */}

                </div>


                <div className='relative w-full   h-64 sm:w-1/2'>

                    <label className='text-2xl mb-3  sm:mb-0 text-gray-400 font-semibold'>VIRTUAL CATWALK</label>

                    <ReactPlayer
                        className='absolute top-0-  left-0 '
                        url='https://attilawolf.info/video/born.m4v'
                        controls={true}
                        width='auto'
                        height='250px'
                    />

                </div>


            </div>


            {/*======================================== ANDROID=================================================*/}





            <div className='grid justify-items-center sm:flex  rounded-lg px-6 pt-3 pb-10 mb-3   ' style={{ backgroundColor: "#18191a" }}>




                <div className='relative w-full   h-64 sm:w-1/2'>


                    <label className='text-2xl mb-3  sm:mb-0 text-gray-400 font-semibold'>DIGITAL COEROGRAPHY</label>





                    <ReactPlayer
                        className='absolute top-0-  left-0 '
                        url='https://attilawolf.info/video/awalk.m4v'
                        controls={true}
                        width='auto'
                        height='250px'
                    />

                </div>






                <div className='mx-3 w-full  sm:w-1/2' >

                    <label className='text-2xl text-gray-400 font-semibold'>CYBER FASHION</label>
                    <article className='text-sm font-thin mt-3'>

                        <p>I am passionate about art, robotics and computer graphics.</p>
                        <img alt='3d model of an andorid ' src={a10} className=' '></img>
                        <p>In this project, I created a union of the holy trinity of my hobbies.</p>

                    </article>

                    {/* <p className=' text-gray-500 pl-2 mt-3 text-xs font-thin'>Share this video</p>
                    <ShareModule size={32} /> */}

                </div>





            </div>







            {/*====================================PHOTOREAL DESIGN========================================*/}





            <div className=' relative  grid justify-items-center sm:flex  rounded-lg pt-3 pb-10 mb-3   ' style={{ backgroundColor: "#18191a" }}>



                <div className='mx-3 w-full px-6 sm:w-1/2' >


                    <label className=' text-2xl text-gray-400 font-semibold'>PHOTOREAL DESIGN</label>
                    <article className='text-sm font-thin mt-3'>

                        <p> Situating items in 3-dimensional virtual space can be chalenging.  </p>
                        <p>I am experimenting with texturing methods that can reproduce "reality" as realistically as possible</p>
                        <p>Cehck out the gallery and see how a wireframe structure became photoreal art.</p>


                    </article>
                    {/* <p className=' text-gray-500 pl-2 mt-3 text-xs font-thin'>Share this project</p>

                    <ShareModule size={32} /> */}

                </div>


                <div className=' relative grid justify-items-center sm:static w-full h-64 sm:w-1/2'>


                    <label className='  text-2xl mb-3  sm:mb-0 text-gray-400 font-semibold'>BLUE BLACK & WHITE</label>

                    {/*IMAGE FULL SIZE*/}

                    {fullSize ?

                        <div className='relative group  '>

                           <Image
                                id='DesignPageContent'
                                onLoad={(e) => handleLoad(e)}
                                publicId="https://res.cloudinary.com/attilawolf/image/upload/v1615239765/attilawolf/design/dd2_vryddg.jpg"
                                cloud_name="attilawolf"
                                loading="lazy">
                                <Transformation quality="52" />
                                <Placeholder
                                    type="vectorize">
                                </Placeholder>
                            </Image>

                            



                           {/* <img alt='photoreal design' src={photoreal1} className=' w-64 sm:w-10/12 '></img>*/}



                            <div onClick={() => setFullSize(!fullSize)} className=' absolute flex justify-center top-1/2  w-11/12 p-1 '>
                                <div className='grid items-center text-center p-2 rounded-xl bg-black bg-opacity-20'>
                                    <span className='text-xs text-gray-100 font-thin cursor-pointer'>open gallery &nbsp;</span>
                                </div>

                            </div>
                        </div> :


                        <div className='absolute grid justify-items-center top-0 left-0 w-full p-2 sm:p-10 bg-black bg-opacity-75 z-40'>

                            <span onClick={() => setFullSize(!fullSize)} className='text-gray-500 justify-self-end cursor-pointer'> x</span>
                            <div  className='gallery-wrapper'>
                                <ImageGallery items={[{ original: "https://res.cloudinary.com/attilawolf/image/upload/v1615239765/attilawolf/design/dd2_vryddg.jpg" },{ original: "https://res.cloudinary.com/attilawolf/image/upload/v1615239765/attilawolf/design/dd1_k6bhcn.jpg" },
                                { original: "https://res.cloudinary.com/attilawolf/image/upload/v1615239766/attilawolf/design/dd4_aei50d.jpg" }, { original: "https://res.cloudinary.com/attilawolf/image/upload/q_44/v1615239766/attilawolf/design/dd3_njpwnw.jpg" }]} />
                            </div>



                        </div>






                    }




                </div>



            </div>






            {/*====================================BRANDING RESEARCH========================================*/}





            <div className=' relative grid justify-items-center sm:flex  rounded-lg  pt-3 pb-10 mb-3   ' style={{ backgroundColor: "#18191a" }}>



                <div className=' w-full px-6  h-64 sm:w-1/2'>


                    <label className='  text-2xl mb-3  sm:mb-0 text-gray-400 font-semibold'>BIOMIMETICS</label>


                    {/*IMAGE FULL SIZE*/}

                    {fullSize2 ?

                        <div className='relative group mt-3'>
                             <Image
                                id='DesignPageContent'
                                onLoad={(e) => handleLoad(e)}
                                publicId="attilawolf/branding/branding1_mumaxr.jpg"
                                cloud_name="attilawolf"
                                loading="lazy">
                                <Transformation quality="52" />
                                <Placeholder
                                    type="vectorize">
                                </Placeholder>
                            </Image>


                           {/*  <img alt='photoreal design' src={branding01} className=' w-11/12 '></img>*/}
                            <div onClick={() => setFullSize2(!fullSize2)} className=' absolute flex justify-center top-1/2  w-11/12 p-1 '>
                                <div className='grid items-center text-center p-2 rounded-xl bg-black bg-opacity-20'>
                                    <span className='text-xs text-gray-100 font-thin cursor-pointer'>open gallery &nbsp;</span>
                                </div>

                            </div>
                        </div> :

                        <div className='absolute grid justify-items-center top-0 left-0 w-full p-2 sm:p-10 bg-black bg-opacity-75 z-40'>

                            <span onClick={() => setFullSize2(!fullSize2)} className='text-gray-500 justify-self-end cursor-pointer'> x</span>
                            <div className='gallery-wrapper'>
                                <ImageGallery items={[{ original: "https://res.cloudinary.com/attilawolf/image/upload/v1615240204/attilawolf/branding/branding1_mumaxr.jpg" }, { original: "https://res.cloudinary.com/attilawolf/image/upload/v1615240205/attilawolf/branding/branding2_g7yhr0.jpg" },
                                { original: "https://res.cloudinary.com/attilawolf/image/upload/v1615240204/attilawolf/branding/branding3_aqnorm.jpg" }, { original: "https://res.cloudinary.com/attilawolf/image/upload/v1615240204/attilawolf/branding/branding4_cjudhc.jpg" },
                                { original: "https://res.cloudinary.com/attilawolf/image/upload/c_scale,q_32,w_1680/v1615240205/attilawolf/branding/branding5_yf6brf.jpg" },
                                { original: "https://res.cloudinary.com/attilawolf/image/upload/v1615240204/attilawolf/branding/branding6_z0fguv.jpg" }, { original: "https://res.cloudinary.com/attilawolf/image/upload/v1615240204/attilawolf/branding/branding7_pa3qoy.jpg" }]} />
                            </div>



                        </div>



                    }



                </div>



                <div className='mx-3 w-full  sm:w-1/2 px-6' >


                    <label className='text-2xl text-gray-400 font-semibold '>BRANDING AN INNOVATION</label>
                    <article className='text-sm font-thin mt-3'>


                        <p>Biomimetics Advanced Insulation and Airconditioning technology was an innovative research proposal that aimed to pioneer a sustainable future of thermal control systems.</p>
                        <p> Presenting function with accessibility to details.
                        Molding innovative technology into a form of an exciting new opportunity.
 </p>


                    </article>

                    {/* <p className=' text-gray-500 pl-2 mt-3 text-xs font-thin'>Share this project</p>
                    <ShareModule size={32} /> */}

                </div>

            </div>




            {/*====================================3D GRAPHICS========================================*/}





            <div className=' relative grid justify-items-center sm:flex  rounded-lg pt-3 pb-10 mb-3   ' style={{ backgroundColor: "#18191a" }}>




                <div className='mx-3 w-full px-6  sm:w-1/2' >


                    <label className='text-2xl text-gray-400 font-semibold'>MIXED REALITY</label>
                    <article className='text-sm font-thin mt-3'>


                        <p>In my spare time, I give form to my ideas and dreams.</p>
                        <p>I enjoy merging real photographs with 3D objects to create visions of mixed reality.</p>



                    </article>

                    {/* <p className=' text-gray-500 pl-2 mt-3 text-xs font-thin'>Share this project</p>
                    <ShareModule size={32} /> */}

                </div>



                <div className=' relative  sm:static w-full   h-64 sm:w-1/2'>


                    <label className='  text-2xl mb-3  sm:mb-0 text-gray-400 px-6 font-semibold'>3D </label>


                    {/*IMAGE FULL SIZE*/}

                    {fullSize3 ?

                        <div className='relative group px-6 '>

                            <Image
                                id='DesignPageContent'
                                onLoad={(e) => handleLoad(e)}
                                publicId="attilawolf/c1_ab47te.jpg"
                                cloud_name="attilawolf"
                                loading="lazy">
                                <Transformation quality="52" />
                                <Placeholder
                                    type="vectorize">
                                </Placeholder>
                            </Image>

                            {/*<img alt='3d Graphics of a city' src={c1} className=' w-11/12'></img>*/}

                            <div onClick={() => setFullSize3(!fullSize3)} className=' absolute flex justify-center top-1/2  w-11/12 p-1 '>
                                <div className='grid items-center text-center p-2 rounded-xl bg-black bg-opacity-20'>
                                    <span className='text-xs text-gray-100 font-thin cursor-pointer'>open gallery &nbsp;</span>
                                </div>

                            </div>
                        </div> :

                        <div className='absolute grid justify-items-center top-0 left-0 w-full p-2 sm:p-10 bg-black bg-opacity-75 z-40'>

                            <span onClick={() => setFullSize3(!fullSize3)} className='text-gray-500 justify-self-end cursor-pointer'> x</span>

                            <div className='gallery-wrapper'>
                                <ImageGallery items={[
                                { original: "https://res.cloudinary.com/attilawolf/image/upload/q_46/v1615218202/attilawolf/c1_ab47te.jpg" },
                                { original: "https://res.cloudinary.com/attilawolf/image/upload/c_scale,q_62,w_1515/v1615218202/attilawolf/c3_hatkqr.jpg" },
                                { original: "https://res.cloudinary.com/attilawolf/image/upload/v1615218202/attilawolf/c2_sjkb8d.jpg" },
                                { original: "https://res.cloudinary.com/attilawolf/image/upload/c_scale,q_43,w_1218/v1615218203/attilawolf/c4_z7aab5.jpg" },
                                { original: "https://res.cloudinary.com/attilawolf/image/upload/v1615218201/attilawolf/c5_y2itgc.jpg" },
                                { original: "https://res.cloudinary.com/attilawolf/image/upload/v1615218202/attilawolf/c6_eusfak.jpg" },
                                { original: "https://res.cloudinary.com/attilawolf/image/upload/v1626109338/attilawolf/c9_zqxztp.jpg" }]} />

                            </div>



                        </div>



                    }



                </div>




            </div>





        </div>
    )

}

export default DesignPage