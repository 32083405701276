import React, { useEffect } from 'react'
import TagLabel from './tagLabel'
import { ShareModule } from './ShareModule'
import { useHistory } from "react-router-dom"
import '../styles/page.scss'




import VisitLogging from '../utils/VisitLogging'
const BlogCard = (props) => {

    const history = useHistory()
    const handleClick = () => {

        history.push(`/blog/${props.url_prefix}`)

        VisitLogging( `/blog/${props.url_prefix}`)
     
    }

    useEffect(() => {
        activity()

    })


    const updated = {
        mostRecent: [' absolute top-0 left-3 w-28 h-8  bg-green-600 transform skew-x-45 ','w-9  h-8 bg-green-600','updated'],
        recent: ['  absolute top-0 left-3 w-32 h-8  bg-blue-600 transform skew-x-45','w-10 h-8 bg-blue-600','new topic'],
        newIdea: ['  absolute top-0 left-3 w-28 h-8  bg-yellow-600 transform skew-x-45  ','w-9  h-8 bg-yellow-600','new idea'],
        notOpen:[ 'absolute top-0 left-3 w-28 h-8   transform skew-x-45  ','w-9  h-8 ',''],
    }

    const activity = () => {

        let signal
        switch (props.activity) {
            case 'mostRecent':
                signal = updated.mostRecent
                break;

            case 'newIdea':
                signal = updated.newIdea
                break;


            case 'leastRecent':
                signal = updated.leastRecent
                break;

                case 'recent':
                    signal = updated.recent
                    break;    

            default: signal = updated.notOnGithub



        }
        return signal
    }
    const color1 = "#121313"
    const color2 = "#18191a"
    const color3 = "#3c3a3a"




return(
    <div className=' card flex flex-col  rounded-xl m-3 shadow-xl  max-w-sm ' style={{ background: `linear-gradient(0deg, ${color1} 0%, ${color2} 59%, ${color3} 100%)` }}>

        <div className='flex justify-between p-1 mr-2 z-20 rounded-t-xl' style={{ background: `linear-gradient(0deg, ${color1} 0%, ${color2} 59%, ${color3} 100%)` }}>
            <label className='text-sm text-gray-500 font-bold px-2 pb-2 pt-1' >{props.Title.toUpperCase()}</label>
           
        </div>


        <div className='group relative overflow-hidden cursor-pointer'>

            <div className='flex justify-end visible group-hover:invisible transform duration ease-in-out duration-800'>

                <div className='absolute   flex  '>
                    <div className='relative transform rotate-45 -top-6 mr-14 '>
                       
                        <div className={activity()[1]}></div>
                        <div className={activity()[0]}></div>

                    </div>
                    <div className=' absolute top-2 left-3 text-white text-sm font-semibold transform rotate-45'>  &nbsp;&nbsp;&nbsp;&nbsp;{activity()[2]}</div>
                </div>
            </div>
            <img src={props.Image} alt='projectImage' onClick={handleClick}></img>


            <div className=' ml-1 opacity-0 absolute top-2  group-hover:opacity-100 transition ease-in duration-200 transition ease-out duration-700' onClick={handleClick}>

                <TagLabel className='grid  grid-cols-3 sm:grid-cols-2 lg:grid-cols-3 grid-rows-4 gap-2 ' tags={props.tags}></TagLabel>

            </div>

        </div>
        
        <div className='px-3 pt-2 pb-4 text-md text-gray-400' ><hr />


            <p>
                {props.Description[0].substring(0 - 15)}... <button className='text-blue-600 text-xs underline' onClick={handleClick}>click for more!</button>
            </p>

        </div>
        <div className='flex justify-end  items-center pl-3 '>

            <span className='text-xs font-thin text-gray-500 leading-3 '>Share  this discussion</span>
            <ShareModule flow='flex justify-around ' size={32} shape={true} url={`https://attilawolf.info/blog/${props.url_prefix}`}shareData={`Hi, check out this discussion: https://attilawolf.info/blog/${props.url_prefix}`} />
        </div>

    </div>
)
}
export default BlogCard