import React from 'react'
import './styles/main.css';

import Navbar from './navbar'
import FrontPage from './pages/FrontPage';
import Footer from './components/Footer'
import ProjectPage from './pages/ProjectPage'
import BlogPage from './pages/BlogPage';
import DesignPage from './pages/DesignPage'
import Blog from './pages/Blog'
import {
  BrowserRouter as Router,
  Switch,
  Route,

} from "react-router-dom";
import { store } from './store/store'
import { Provider } from 'react-redux'

function App() {





  return (
    <Provider store={store}>

    <Router>
      <div className='fixed z-40 w-full h-16' style={{ backgroundColor: '#18191a' }} ></div>
      <div className='w-full ' style={{ backgroundColor: '#2a2a2a' }}>
        <div className="max-w-screen-md mx-auto bg-gray-700 ">
          <Navbar />

          <Switch>
            <Route path="/" component={FrontPage} exact />
            <Route path="/projects/:id" component={ProjectPage} />
            <Route path="/blog/:id" component={BlogPage} />
            <Route path="/design" component={DesignPage} />
            <Route path="/blog" component={Blog} />

          </Switch>

        </div>
        <Footer />
      </div>

    </Router>
    </Provider>




  );
}


export default App;
