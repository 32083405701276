import React, { useState, useEffect } from 'react'
import LongButton from '../components/LongButton'
import { GrStatusInfo } from "react-icons/gr";
import $ from "jquery";
import axios from 'axios'




const ContactCard = (props) => {

  const [name, setFirstname] = useState('')
  const [lastName, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [userPwD, setuserPwd] = useState('')
  const [userUid, setuserUid] = useState('')

  const [sending, setSending] = useState(false)
  const [barDone, setbarDone] = useState(false)
  const [messageSent, setMessageSent] = useState(false)

  

  const onSubmit = (e) => {
    e.preventDefault();

   


   if(validateEmail(email)){
   
      
    setSending(!sending)


    const param = props.signup?{
      args: {
        userName: name,
        userUid: userUid,
        userEmail: email,
        userPwD:userPwD
      }
    }:
    {
      args: {
        firstName: name,
        lastName: lastName,
        email: email,
        message: message,
      }
    }

    //http://localhost:3001/index.php
props.signup? axios({
      method: "POST",
      url: "/post_maria.php",
      data: param,

    }).then((response) => {
      
       console.log("POST: ",response)
       resetForm()
      
    }).catch(err => {
      console.error(err);
  })

    :
    axios({
      method: "POST",
      url: "/index.php",
      data: param,

    }).then((response) => {
     
        resetForm()
     
    }).catch(err => {
      console.error(err);
  })

  //resetForm()
  }else{ 
    $("#email").val(" Please enter a valid email!")}

  }


  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }




  function resolveFailure(jqXhr) {

    alert("Something went wrong please try again later")
    console.log("status: ", jqXhr.status, "status text:", jqXhr.statusText)


  }


  const setBar = () => {
    let barStyle = {

      barActive: '',
      fillBar: ''
    }

    if (sending && !barDone) {
      barStyle.barActive = 'absolute top-1 left-0 h-1 w-full visible'
      barStyle.fillBar = 'filledbar w-full transform ease-out duration-700 '


    } else {
      barStyle.barActive = 'absolute top-1 left-0 h-1 w-full visible invisible'
      barStyle.fillBar = 'filledbar w-0'

    }
    return barStyle
  }




  function resetForm() {

    setTimeout(function () {
      $("#name").val("")
      $("#lastName").val("")
      $("#email").val("")
      $("#message").val("")
     

     if(document.getElementById("password")!==null){
      $("#password").val("")
      $("#userName").val("")
     }
      setMessageSent(!messageSent)
      setbarDone(!barDone)


    }, 2000)

  };


  return (



    <div className='relative'>



      <div className={setBar().barActive}>
        <div className="emptybar"></div>
        <div className={setBar().fillBar}></div>


      </div>

      { messageSent &&
        <div className=' w-full h-full  grid items-center absolute bg-gray-600 text-green-400 text-md font-semibold rounded-xl opacity-80 text-center '>
          <button className=' absolute  top-2 right-3 text-white' onClick={() => setMessageSent(!messageSent)}> Close</button>
          <div className=' grid items-center bg-black opacity-100'>

            <p className=' text-2xl  bg-green-400 flex ml-auto mr-auto '>
              <GrStatusInfo /></p>
              {props.signup?<p>An email with login info was sent to you</p>:<p>Message Successfully sent</p>}
            

          </div>

        </div>}


      <form className='flex flex-col p-3'
        onSubmit={e => onSubmit(e)}>

        <div className='sm:flex mb-3 justify-between'>



          <div className='flex flex-col'>
            <label htmlFor='name' className='text-sm text-thin text-gray-600' >{props.signup?"Name":"First Name"}</label>
            <input required={true} id='name' placeholder=' John' className='border border-2 border-gray-200 mr-1' type='text'
              onChange={e => setFirstname(e.target.value)}></input>

          </div>

       { props.signup?<></> : <div className='flex flex-col'>
            <label htmlFor='lastName' className='text-sm text-thin text-gray-600 ml-3'>Last Name</label>
            <input id='lastName' placeholder=' Wick' type='text' className='border border-2 border-gray-200  mr-1'
              onChange={e => setLastname(e.target.value)}></input>
          </div>}

        </div>

        {props.signup?<>
        <label htmlFor='userName' className='text-sm text-thin text-gray-600'>Username</label>
        <input required={true} id='userName' placeholder='set your user name' type='text' className='border border-2 border-gray-200 mb-3'
          onChange={e => setuserUid(e.target.value)}></input></>:<></>}

        <label htmlFor='email' className='text-sm text-thin text-gray-600'>Email</label>
        <input required={true} id='email' placeholder=' youremail@mail.com' type='text' className='border border-2 border-gray-200 mb-3'
          onChange={e => setEmail(e.target.value)}></input>

      {props.signup?<>
        <label htmlFor='password' className='text-sm text-thin text-gray-600'>Password</label>
        <input required={true} id='password' placeholder='set your password' type='text' className='border border-2 border-gray-200 mb-3'
          onChange={e => setuserPwd(e.target.value)}></input></>:<></>}
{props.signup?<></>:<>
        <label htmlFor='message' className='text-sm text-thin text-gray-600'>{props.signup?"Introduce Yourself in a few words":"Message"}</label>
        <textarea required={true} id='message' placeholder={props.signup?' Please type in your intro':' Please type in your message'} className='border border-2 border-gray-200 mb-3'
          onChange={e => setMessage(e.target.value)}></textarea></>}

{props.signup?<LongButton type='submit' variant="signup">SIGN UP</LongButton>:<LongButton type='submit'>SEND MESSAGE</LongButton>}
        


      </form>
    </div>





  )

}

export default ContactCard