import b1 from './images/blog/b1.jpg'
import b2 from './images/blog/b2.jpg'





export const blog={ "blog_data":[

    {"url_prefix":"Matter_over_Mind",
    "blog_Title":"MATTER OVER MIND",
    "blog_Images":[{original: b1, }],
    "blog_description":["An attempt to become more of what you are. "],
    "tags":["Mind","CNS", "Brain", "Thoughts"],
    "blog_highlights":["How to gain access of our hidden potential",
" The soultion is up to us to come up with:",
   " View/browse all available scinetific articles, ",
"Connect users via message form or chat." ],
    "blog_highlight_Intro":"Experimenting with the mind",
    "githubLink":"",
    "live":"",
    "activity":"mostRecent"
    },



    {"url_prefix":"DeveloperJourney",
      "blog_Title":"DEVELOPED",
    "blog_Images":[{
      original:b2, }],
    "blog_description":["A journey from the ivory tower.",],

    "tags":["Life lessons","Disciplines"],
    "blog_highlights":"What helps and whats not",
    "blog_highlight_Intro":"Learn create repeat",
    "githubLink":"",
    "live":"",
    "activity":"recent"
    },
    
      ]
    }