import r1 from './images/realestate/r1.jpg'
import ui1 from './images/ui/ui1.jpg'
import ui2 from './images/ui/ui2.jpg'
import ui3 from './images/ui/ui3.jpg'
import gu1 from './images/gusers/gu1.jpg'
import ph1 from './images/phaser/ph1.jpg'
import bp1 from './images/blog_post/bp1.jpg'
import bp2 from './images/blog_post/bp2.jpg'
import c1 from   './images/counseling/c1.jpg'
import e1 from './images/email/e1.jpg'
import t1 from './images/tutorial/demo1.jpg'
import t2 from './images/tutorial/demo2.jpg'
import t3 from './images/tutorial/demo3.jpg'
import t4 from './images/tutorial/demo4.jpg'
export const project={ "project_data":[

    {"url_prefix":"realestate",
    "project_Title":"REALESTATE",
    "project_Images":[{original: r1, }],
    "project_description":["An MVP for a new Real Estate application to provide consumers with the oppurtinity to get in touch with the seller. "],
    "tags":["ReactJS","SCSS", "Flux", "MongoDB"],
    "project_highlights":["The app mimics API calls via Flux event dispatches and handle/store data as though it was received from an API call.",
" The soultion  provides the following core functionality:",
   " View/browse all available homes, ",
"Filter homes by City, Price, Number of Bedrooms, ",
    "Show detailed view for a selected home,",
    " Share current property, ",
"Connect with owner/agent via message form or chat." ],
    "project_highlight_Intro":"Experimenting with chat and message modals",
    "githubLink":"https://github.com/perokyn/running-fox-ui-mockup.git",
    "live":"https://attilawolf.info/realestate/",
    "activity":"mostRecent"
    },



    {"url_prefix":"profile_ui",
      "project_Title":"PROFILE UI",
    "project_Images":[{
      original: ui1, },{ original: ui2,},{original: ui3, }],
    "project_description":["A simple profile page for Freelance Business.",
      " Modular profile theme for a web platform that lists small business and freelancer services.",
      " Users can set up an hourly or a fixed rate and select a payment plan"],

    "tags":["ReactJS","SCSS", "Tailwind"],
    "project_highlights":"A quick front-end experiment. Used React to create re-usable modules, Tailwind for quick inline styling and SCSS to design the range slider for price setup.",
    "project_highlight_Intro":"React, SCSS and Tailwind CSS hand in hand.",
    "githubLink":"https://github.com/perokyn/running-fox-ui-mockup.git",
    "live":"http://visualvicinity.com/uimockup/",
    "activity":"recent"
    },




    {"url_prefix":"follow_blog_posts",
      "project_Title":"BLOG POSTS",
        "project_Images":[{original: bp1, },{ original: bp2,}],
        "project_description":["Web Application using Next.js",
          " Follow all of your impoprtant blogs with a simple WebApp using Airtable as backend solution. Using this app members of a team can view the latest posts from a list of blogs they selected. ",
          "Members can click the “Add a new blog” link and submit a new blog though a form. Each addition can be approved by an admin via the Airtable app."],
        "tags":["API","TAILWIND", "NEXTJS","VERCEL"],
        "project_highlights":"The core of this app is a neat  Next.js feature which makes it possible for the home page and the form pages to be static pages, generated at build time, but at the same time, the API will be handled by the server-side code.",
        "project_highlight_Intro":"Symbiosis between static pages and server-side API",
        "githubLink":"https://github.com/perokyn/blogposts-with-nextjs.git",
        "live":"https://blogposts-with-nextjs.vercel.app/",
        "activity":"recent"
    },



    {"url_prefix":"game_design",
      "project_Title":"GAME DESIGN",
        "project_Images":[{original: ph1, }],
        "project_description":["A very very simple game that can be turn into a very demanding one when adding time constrains. A great way to experiment with ",
          "Phaser and JavaScript."],
        "tags":["PHASER","SCSS", "JS"],
        "project_highlights":"This project used parcel-bundler and browser-sync to make development easy and instantenous.",
        "project_highlight_Intro":"This is a work in progress project, but you can already try it!",
        "githubLink":"https://github.com/perokyn/pahser_game.git",
        "live":"https://pahser-game.vercel.app/",
        "activity":"newIdea"
    },

    {"url_prefix":"search_github_users",
      "project_Title":"GITHUB API",
        "project_Images":[{original: gu1, }],
        "project_description":[" Create an app that consumes the GitHub Public API. An app that uses plain JavaScript, without any frontend framework", " interfaced with the GitHub public REST API."],
        "tags":["JS","CSS", "API"],
        "project_highlights": ["A simple SPA that uses the GitHub API to retrieve information of user profiles. Only using the public API endpoints so there is no authentication is needed.",
           " The functionality provides a search form to interact with GitHub and get some data about a specific user. ",
           "Additional fetures are: direct link to user profile, direct link to user web-page (if any)."        
      ],
        "project_highlight_Intro":"Get it from github",
        "githubLink":"https://github.com/perokyn/GitHub-User-Search-with-React-Axios.git",
        "live":"http://www.visualvicinity.com/gitusersearch/index.html",
        "activity":"recent"
    },

    {"url_prefix":"counseling_game",
    "project_Title":"COUNSELING",
      "project_Images":[{original: c1, }],
      "project_description":["  This is a work in progress project! Create a board game for kids using Tailwinds and JQuery", " interfaced with the Airtable public REST API, the game content can be update on the fly."],
      "tags":["JS","Tailwind", "API"],
      "project_highlights": ["This is a work in progress project!!! But you can alreay roll the dice so chek it out live :)",
         "Functionality will include updating game content as needed using the Airtable App. ",
         " This App is intended to aid online counseling for kids."        
    ],
      "project_highlight_Intro":"Asking the right question can make big differences!",
      "githubLink":"https://github.com/perokyn/Counseling-Game.git",
      "live":"https://attilawolf.info/counseling_game/",
      "activity":"newIdea"
  },
  {"url_prefix":"html_email",
    "project_Title":"HTML EMAILS",
      "project_Images":[{original: e1, }],
      "project_description":["Creating, coding, testing (Litmus, Veeva, Salesforce) and maintaining email templates according to industry-standard best practices."],
      "tags":["HTML","CSS"],
      "project_highlights": ["This project was recently added. Codes and live samples are coming soon!"        
    ],
      "project_highlight_Intro":"UPDATES COMING SOON!",
      "githubLink":"",
      "live":"",
      "activity":""
  },
  {"url_prefix":"tutorial_demo",
    "project_Title":"TUTORIAL DEMO",
      "project_Images":[{original: t1},{original:t2},{original:t3},{original:t4}],
      "project_description":["Animated tutorial module for online assessments. Exploration and integration of animation libraries into react, redux based applications."],
      "tags":["JS","React","Redux","AnimeJS"],
      "project_highlights": ["Working with design systems and developing animated icons to guide users through various tutorial scenarios."        
    ],
      "project_highlight_Intro":"",
      "githubLink":"",
      "live":"https://attilawolf.info/video/ets_tutorial.mp4",
      "activity":"notOnGithub"
  },
  
  // {"url_prefix":"banners",
  //   "project_Title":"BANNERS",
  //     "project_Images":[{original: c1, }],
  //     "project_description":["  This is a work in progress project! Create a board game for kids using Tailwinds and JQuery", " interfaced with the Airtable public REST API, the game content can be update on the fly."],
  //     "tags":["JS","Tailwind", "API"],
  //     "project_highlights": ["This is a work in progress project!!! But you can alreay roll the dice so chek it out live :)",
  //        "Functionality will include updating game content as needed using the Airtable App. ",
  //        " This App is intended to aid online counseling for kids."        
  //   ],
  //     "project_highlight_Intro":"Asking the right question can make big differences!",
  //     "githubLink":"https://github.com/perokyn/Counseling-Game.git",
  //     "live":"https://attilawolf.info/counseling_game/",
  //     "activity":"newIdea"
  // }
    
      ]
    }





   







