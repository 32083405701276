import React from 'react'
import '../styles/page.scss';
import {

  LinkedinIcon,
  TwitterIcon,
  

} from 'react-share';

import { FaGithub, FaInstagram } from "react-icons/fa"
import {TiArrowDownOutline} from 'react-icons/ti'
import me2 from '../assets/images/me2.png'
import VisitLogging from '../utils/VisitLogging'

const AboutMe = (props) => {

  const handleLoad=(e)=>{
    VisitLogging("HomePage") 

}


  return (
    <div className=' flex   '>


      {/*<div className='bg '>
      <div className='circle '></div>
     </div>*/ }
      <div className=' z-10  flex flex-wrap-reverse sm:flex mt-20  '>
      
        <div className='flex mt-4 sm:mt-0 relative'>

        <div className=' absolute -top-4  w-16 sm:-top-4 text-xs font-thin text-gray-500'>Follow Me</div> 

          <div className='  sm:absolute h-16' style={{ background: '#007fb1' }}>
            <LinkedinIcon onClick={() => window.open("https://linkedin.com/in/attilajosephfarkas", "_blank")} size={60} className='cursor-pointer border border-transparent ml-0 hover:ml-4 translate ease-in-out duration-200' />
          </div>

          <div className=' sm:absolute h-16 top-16 bg-gray-600' >
            <div className=' cursor-pointer grid items-center justify-center  bg-gray-600 w-16 h-16 border border-transparent ml-0 hover:ml-4 translate ease-in-out duration-200' >
              <FaGithub onClick={() => window.open("https://github.com/perokyn", "_blank")} size={45} />
            </div>
          </div>

          <div className='sm:absolute  h-16 top-32' style={{ background: '#00aced' }}>
            <TwitterIcon onClick={() => window.open("https://twitter.com/Attila97622189", "_blank")} size={60} className='cursor-pointer border border-transparent ml-0 hover:ml-4 translate ease-in-out duration-200' />
          </div>


          <div className=' sm:absolute  h-16 top-48 ' style={{ background: 'linear-gradient(45deg,#405DE6, #5851DB, #833AB4,#C13584,#E1306C, #FD1D1D)' }} >
            <div className=' cursor-pointer grid items-center justify-center  w-16 h-16  ml-0 hover:ml-4 translate ease-in-out duration-200 text-white'
              style={{ background: 'linear-gradient(45deg,#405DE6, #5851DB, #833AB4,#C13584,#E1306C, #FD1D1D)' }} >
              <FaInstagram href='https://linkedin.com/' size={45} />
            </div>
          </div>








        </div>

<div className=' sm:flex w-full '>

  <div className=' p-4 rounded-lg  sm:ml-20 w-1/2'>
        <img  onLoad={e=>{handleLoad(e)}} className=' w-40  ' alt="attila" src={me2}></img>

        </div>
      
       <div className='text-2xl font-bold text-gray-300   ' >
         <p>
         Hello my  Name is Attila
        
         </p>
         <br/>
         <p className='text-md font-thin'>
         I am a front-end developer with 3D motiongraphics and research skills.
         <button className=' flex items-center text-gray-300 text-md font-thin' onClick={props.onClick}> 
        Check out my latest projects !
        <TiArrowDownOutline  href='#projects' className=' text-yellow-300 text-3xl animate-bounce'/>
        </button>
         </p>

         
         <br></br>
         <br></br>

         <div className='type '>
         <p className='text-sm font-thin '>Remotely available:</p>
         <p className='text-sm font-thin '> UTC−1 to UTC+8 attila.f.j@gmail.com <span className='caret'>|</span></p>
         </div>
        
         </div>
        </div>

      </div>


    


      
    </div>
  )

}

export default AboutMe