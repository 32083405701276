
import React,{useEffect} from 'react'
import { ShareModule } from '../components/ShareModule'
import { FiExternalLink, FiGithub } from "react-icons/fi"
import { useLocation } from "react-router-dom"
//import data from '../data.json'

import {project} from '../assets/Data'
import $ from "jquery";

import ImageGallery from 'react-image-gallery';

const ProjectPage = ({match}) => {

const id = match.params.id
const currentProject=project.project_data
const article=currentProject.find(article=>article.url_prefix===id)
const location=useLocation()
//console.log("Project data: ",article)

//THIS STILL HAS DATA FROM JSON FILE WHICH GETS ITS DATA FROM THE OLD VISUALVICINITY SITE HENCE NO IMAGES11


const loadItems=()=>{
    return new Promise(resolve => setTimeout(resolve, 1000)) // 2 seconds
  }



useEffect(()=>{
   

     
            const ele = document.getElementById('ipl-progress-indicator')
            console.log("component_mounted",ele)
    
    
            loadItems().then(() => {
                const ele = document.getElementById('ipl-progress-indicator')
                const ele2 = document.getElementById('container')
                if(ele){
                  // fade out
                  ele.classList.add('available')
                  ele2.classList.add('available')
                  setTimeout(() => {
                    // remove from DOM
                    ele.outerHTML = ''
                    ele2.outerHTML = ''
                  }, 1000)
                }
              })
    
    


    $(document).ready(function (){
        $(function (){
            $('html, body').animate({
                scrollTop: $("#pageContent").offset().top-200
            }, 1000);
        });
    });






})

    return (
        <div className='text-white pt-20 px-6 sm:px-16' style={{backgroundColor:'#2a2a2a'}}>

            <div id='pageContent'>

                <div id='pageHeader'>
                    <div id='projectTitle'>
    <h3 className='text-3xl text-gray-200 font-bold'>{article.project_Title}</h3>
                        <br />
    <p className='text-md text-gray-400'>{article.project_description}</p>
                    </div>
                    <br />
                    <div className='sm:flex  justify-between items-center' id='shareModules'>

                        <button className='flex w-full sm:w-48 sm:mt-3 justify-center bg-green-400 px-2 h-12 text-sm items-center 
                        text-white rounded-lg mr-2 focus:outline-none focus:ring-2 focus:ring-green-200 focus:border-transparent'
                        onClick={()=>window.open(article.live, "_blank") } > <FiExternalLink className='text-xl' />VISIT THE WEBSITE</button>
                        <div>
                        <span className=' text-gray-400 text-xs font-thin'>Share this project:</span>
                        <ShareModule size={52} url={`https://attilawolf.info${location.pathname}`} shareData={`Hi, check out this font-end project: https://attilawolf.info${location.pathname}`}></ShareModule>
                        </div>
                    </div>
                </div>
                <br />

                 <div className='relative  '>

                    <ImageGallery items={article.project_Images}/>

                       


                   
                </div>


                <br />
                <h3 className='text-3xl text-gray-200 font-bold'>Project highlights</h3>
                 
                <p className='text-md text-yellow-300'>{article.project_highlight_Intro}</p>
                <br/>
                 <hr />

                 <article className='text-md text-gray-400'>{article.project_highlights}
                 <br/>
                 
                 </article>

                <br></br>
                <h3 className='text-3xl text-gray-200 font-bold'>Tech Sheet</h3>

                <br />

                <hr />
                <br />
                <ul style={{ listStyleType: 'circle' }} className='text-md text-gray-400 font-semibold'>

                    
                    {article.tags.map((tag,key)=>(
                  <li key={key}>{tag}</li>

                    ))}
                    
                </ul>


                <br />
                <p className='text-md text-gray-200 font-bold'>Source Code</p>
                    <p className=' flex  p-2 text-md text-gray-400 font-bold' ><FiGithub/> <a href={article.githubLink} className=' px-2 text-sm text-blue-200 font-bold underline'>{article.githubLink}</a></p>
                <br/>
            </div>



    </div>
    )




}

export default ProjectPage