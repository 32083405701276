import $ from "jquery";
import axios from 'axios'




const VisitLogging=(pageName)=>{

    const today = new Date();
    const date =
      today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
    const time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    const dateTime = date + " " + time;
  
  




    const param = {
        args: {
          pageVisited:pageName,
          visitDate: dateTime,
          
        }
      }
  
    
      //http://localhost:3001/index.php
  
      axios({
        method: "POST",
        url: "/log.php",
        data: param,
  
      }).then((response) => {
        if (response.statusText === "OK") {
  
  
         // console.log("RESPONSE", response)
  
        }
      })
  






}


export default VisitLogging