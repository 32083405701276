
import axios from 'axios'
export const setServerLogin=(logvalue,name)=>{
    const param = {
      args: {
        userUid: name,
        logValue:logvalue,
          }
    }
    axios({
      method: "POST",
      url: "/logged.php",
      data: param,
  
    }).then((response) => {
       
   return response.data
    })
    
  }


  export const getComments=(name,blogName)=>{

    const param = {
      args: {
        userUid: name,
        blogName:blogName,
          }
    }

    let output
    axios({
      method: "POST",
      url: "/comments.php",
      data: param,

    }).then(function (response) {
        if (response.data.error) {
          console.log("PHP error: ",response.data.message) 
        } else {
          
          // setBlogData(response.data)
          console.log("COmment", response)
         output=  response.data
        }
      });
      return output
  }

  