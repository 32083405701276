import React,{useEffect} from 'react'
import ProjectCardList from '../components/ProjectCardList'
import Skills from '../components/Skills'
import ContactCard from '../components/ContactCard'
import AboutMe from '../components/AboutMe'
import { AiOutlineAppstore,  } from "react-icons/ai";
import {BiMailSend, } from 'react-icons/bi'
import { GiSkills } from "react-icons/gi";
import {project} from '../assets/Data'
import $ from "jquery";



const FrontPage = () => {



// const loadItems=()=>{
//     return new Promise(resolve => setTimeout(resolve, 1000)) // 2 seconds
//   }

//     useEffect(()=>{
//        /* const ele = document.getElementById('ipl-progress-indicator')
//         console.log("component_mounted",ele)*/
// /*REMEMBER ele2 is  acontainer clas you added to index html to add text to page later on btw try to add svg text instead.!!*/ 

//         loadItems().then(() => {
//             const ele = document.getElementById('ipl-progress-indicator')
//             const ele2 = document.getElementById('container')
//             if(ele){
//               // fade out
//               ele.classList.add('available')
//               ele2.classList.add('available')
//               setTimeout(() => {
//                 // remove from DOM
//                 ele.outerHTML = ''
//                 ele2.outerHTML = ''
//               }, 500)
//             }
//           })


        
//          })



    /*const getData=()=>{

        console.log("DATA");
        fetch('./data.json'
        ,{
          headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }
        }
        )
          .then(function(response){
            console.log(response)
            return response.json();
          })
          .then(function(myJson) {
            console.log("DATA",myJson);
            setData(myJson)
          });
      }
      const [data,setData]=useState([]);

      useEffect(()=>{
        getData()
      },[])*/





const projectData = project.project_data

//    console.log("data",projectData)

 

    const goToProjects = () => {
       
       /* $('html, body').animate({
            scrollTop: $("#projects").offset().top
        }, 7000);*/

        $(document).ready(function (){
            $(function (){
                $('html, body').animate({
                    scrollTop: $("#projects").offset().top-200
                }, 2000);
            });
        });
               
    }



    return (
        <div className='pt-10 bg-gray-700' style={{ backgroundColor: '#2a2a2a' }}>
          
            <div className='ml-5 p-2'>
                <AboutMe onClick={goToProjects} />
             
            </div>


            <div className=' pl-3 ml-3 mt-10' >
                <label className='items-center text-2xl text-gray-500 ml-5 font-semibold mt-2 mb-0 '> <AiOutlineAppstore className='text-gray-500 mr-2' />PROJECTS </label>
               
            </div>

 
         {projectData && projectData.length>0   && <div id='projects'>
            <ProjectCardList  projects={projectData} />
            </div>}


            <label className=' flex items-center text-2xl text-gray-500 ml-5 font-semibold my-2 '> <GiSkills className='text-gray-500 mr-2' />SKILLS </label>
             
            <Skills  goProjectLoc={()=>goToProjects()}/>
         

            <label className=' flex items-center text-2xl text-gray-500 ml-5 font-semibold my-2 '> < BiMailSend className=' text-3xl text-gray-500 mr-2' />CONTACT </label>
            <div className=' grid align-items-center m-2 sm:flex rounded-xl p-2  mt-3 '>

                <div className='flex flex-col  rounded-xl p-3 text-gray-400' style={{ backgroundColor: "#18191a" }}>
                    <h3 className='text-lg font-semibold '>Get in touch</h3>
                    <hr />
                    <p className='p-3 text-sm text-gray-400'>
                        If you want to get in touch, discuss a project idea, collaboration or just want to see a nice colorful progress bar,
                        fill up the contact form and hit send. Otherwise you can just send an email to attila(at)attilawolf.info.


         </p>

                </div>

                <div id="contact" className=' rounded-xl  mt-3 sm:mt-0 sm:mx-2 p-3' style={{ backgroundColor: "#18191a" }}>
                    <h3 className='text-lg font-semibold text-gray-400'>Share Your Thoughts</h3>
                    <hr />
                    <ContactCard />
                </div>
            </div>

        </div>
    )

}

export default  FrontPage