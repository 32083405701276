import React from 'react'
import { SiLinkedin, SiResearchgate, SiGithub, SiTwitter } from "react-icons/si";
import {AiOutlineDownload, AiOutlineMail} from 'react-icons/ai'
const Footer = () => {


    return (

        <div className=' p-8 sm:flex ' style={{backgroundColor:'#18191a'}}>
        
        <div className='sm:flex justify-start'> 
        <div className='flex flex-col m-2'> 
        <h3 className='text-sm text-gray-200 m-2 hover:underline'><a href='/#'>About</a></h3>
            <h3 className='text-sm text-gray-200 ml-2'>Contact:</h3>
            <a href="mailto:attila@attilawolf.info" className=' flex items-end jsutify-between ml-2 hover:underline text-xs text-gray-200 '> <AiOutlineMail/> &nbsp;attila(at)attilawolf.info</a>
            </div>
            
            
            <div className='flex m-2'> 
               <div className='flex flex-col'>

                    <h3 className='text-sm text-gray-200 m-2'>Follow Me:</h3>


                    <div className='flex'>
                        <SiLinkedin onClick={() => window.open("https://linkedin.com/in/attilajosephfarkas", "_blank")} className='text-3xl text-gray-300 m-2 hover:text-white cursor-pointer' />
                        <SiTwitter onClick={() => window.open("https://twitter.com/Attila97622189", "_blank")} className='text-3xl text-gray-300 m-2 hover:text-white cursor-pointer' />
                        <SiGithub onClick={() => window.open("https://github.com/perokyn", "_blank")}  className='text-3xl text-gray-300 m-2 hover:text-white cursor-pointer' />
                        <SiResearchgate onClick={() => window.open("https://www.researchgate.net/profile/Attila_Farkas6", "_blank")} className='text-3xl text-gray-300 m-2 hover:text-white cursor-pointer' />
                    </div>
                </div>

            </div>
           
        </div>
        <a className='text-sm text-gray-200 ml-3 mt-2 hover:underline flex'  href="https://attilawolf.info/resume/attila_j_farkas_cv.pdf"> <AiOutlineDownload/>&nbsp;Download Resume</a>
        </div>
    )
}

export default Footer