import React, { useState, useEffect } from 'react'
import LongButton from '../components/LongButton'
import { GrStatusInfo } from "react-icons/gr";
import $, { parseJSON } from "jquery";
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'
import { setLogged, setCurrentUser } from '../reducers/loginSlice'
import { setServerLogin } from '../utils/comm';

const LoginCard = (props) => {
  const userLogged = useSelector((state) => state.login.loggedIn)

  const [name, setFirstname] = useState('')
  const [email, setEmail] = useState('')
  const [pass, setPassword] = useState('')

  const [sending, setSending] = useState(false)
  const [barDone, setbarDone] = useState(false)
  const [messageSent, setMessageSent] = useState(false)
  const [authOK, setAuthOk]=useState(false)

  // const count = useSelector((state) => state.login.value)
  const dispatch = useDispatch()
// consider removing this from store as it is now handled by react set cookies
//installed react cookies on 11/3
//see src@: https://www.tutorialspoint.com/how-to-set-cookies-in-reactjs
//npm install react-cookie

//REMOVE COOKIES FOR NOW ADDED logged into database 0/1


  const onSubmit = (e) => {
    
    e.preventDefault();

   if(validateEmail(email)){
   
    setSending(!sending)

    const param = {
      args: {
        userUid: name,
        userEmail: email,
        userPwD: pass
      }
    }

    //http://localhost:3001/index.php

    axios({
      method: "POST",
      url: "/access.php",
      data: param,

    }).then((response) => {
     
      if(response.data===1){setTimeout(()=>{dispatch(setLogged(true))},2800)
    console.log("Login true")
    setServerLogin(1,name)
    dispatch(setCurrentUser(name))
    setAuthOk(true)
     }else if(response.data!==1){dispatch(setLogged(false))
      console.log("Login fasle")
      setAuthOk(true)}

    })

    resetForm()
  }else{ 
    $("#email").val(" Please enter a valid email!")}

  }

  // continue form here to set lgvalue to 0 when logging out 11/11/2022
// const setServerLogin=(logvalue)=>{
//   const param = {
//     args: {
//       userUid: name,
//       logValue:logvalue,
//         }
//   }
//   axios({
//     method: "POST",
//     url: "/logged.php",
//     data: param,

//   }).then((response) => {
     
//   console.log("Server Login: ",response.data)
 
//   })

  
// }
  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }




  function resolveFailure(jqXhr) {

    alert("Something went wrong please try again later")
    console.log("status: ", jqXhr.status, "status text:", jqXhr.statusText)


  }


  const setBar = () => {
    let barStyle = {

      barActive: '',
      fillBar: ''
    }

    if (sending && !barDone) {
      barStyle.barActive = 'absolute top-1 left-0 h-1 w-full visible'
      barStyle.fillBar = 'filledbar w-full transform ease-out duration-700 '


    } else {
      barStyle.barActive = 'absolute top-1 left-0 h-1 w-full visible invisible'
      barStyle.fillBar = 'filledbar w-0'

    }
    return barStyle
  }




  function resetForm() {

    setTimeout(function () {
      $("#name").val("")
      $("#email").val("")
      $("#message").val("")
      $("#password").val("")


      setMessageSent(!messageSent)
      setbarDone(!barDone)


    }, 2000)

  };


  return (



    <div className='relative'>



      <div className={setBar().barActive}>
        <div className="emptybar"></div>
        <div className={setBar().fillBar}></div>


      </div>

      { messageSent &&
        <div className={` w-full h-full  grid items-center absolute bg-gray-600 ${authOK?'text-green-400':'text-red-400'} text-md font-semibold rounded-xl opacity-80 text-center `}>
          <button className=' absolute  top-2 right-3 text-white' onClick={() => setMessageSent(!messageSent)}> Close</button>
          <div className=' grid items-center bg-black opacity-100'>

            <p className={` text-2xl ${authOK?'bg-green-400':'bg-red-400'} flex ml-auto mr-auto `}>
              <GrStatusInfo /></p>
          { authOK? <p>Logged in successfully</p>:<p>Invalid Password, Please try again</p>}

          </div>
         {!authOK? <div className='absolute bottom-4 grid gap-8 grid-cols-2 ml-8'>
          <div className='text-white underline cursor-pointer hover:text-gray-400'>Forgot password?</div>
          <div className='text-white underline cursor-pointer hover:text-gray-400'>Forgot username?</div>
          </div>:<></>}

        </div>}


      <form className='flex flex-col p-3'
        onSubmit={e => onSubmit(e)}>

        <div className='sm:flex mb-3 justify-between'>



          <div className='flex flex-col'>
            <label htmlFor='name' className='text-sm text-thin text-gray-600' >User Name</label>
            <input required={true} id='name' placeholder=' John' className='border border-2 border-gray-200 mr-1' type='text'
              onChange={e => setFirstname(e.target.value)}></input>

          </div>

          

        </div>

        <label htmlFor='email' className='text-sm text-thin text-gray-600'>Email</label>
        <input required={true} id='email' placeholder=' youremail@mail.com' type='text' className='border border-2 border-gray-200 mb-3'
          onChange={e => setEmail(e.target.value)}></input>

<label htmlFor='password' className='text-sm text-thin text-gray-600'>Password</label>
        <input required={true} id='password' placeholder=' password' type='text' className='border border-2 border-gray-200 mb-3'
          onChange={e => setPassword(e.target.value)}></input>

{props.signup?<LongButton  type='submit' variant="signup">SIGN UP</LongButton>:<LongButton type='submit'>LOGIN</LongButton>}
        


      </form>
    </div>





  )

}

export default LoginCard