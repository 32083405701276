import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 0,
  loggedIn:false,
  currentUser:""
}
export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    },
    setLogged:(state,action)=>{
    state.loggedIn=action.payload
    },
    setCurrentUser:(state,action)=>{
      state.currentUser=action.payload
      },
  },
})
// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount,setLogged,setCurrentUser } = loginSlice.actions

export default loginSlice.reducer