import React, { useEffect } from 'react'
import TagLabel from './tagLabel'
import { FiExternalLink, FiGithub } from "react-icons/fi"
import { ShareModule } from './ShareModule'
import { useHistory } from "react-router-dom"
import '../styles/page.scss'

import VisitLogging from '../utils/VisitLogging'
const ProjectCard = (props) => {


    const history = useHistory()
    const handleClick = () => {

        history.push(`/projects/${props.url_prefix}`)

        VisitLogging( `/projects/${props.url_prefix}`)
     
            

    }

    useEffect(() => {
        activity()

    })


    //const location = useLocation()
    //console.log("link", location)


    const updated = {


        mostRecent: [' absolute top-0 left-3 w-28 h-8  bg-green-600 transform skew-x-45 ','w-9  h-8 bg-green-600','updated'],
        recent: ['  absolute top-0 left-3 w-28 h-8  bg-blue-600 transform skew-x-45','w-9  h-8 bg-blue-600','loading :)'],
        newIdea: ['  absolute top-0 left-3 w-28 h-8  bg-yellow-600 transform skew-x-45  ','w-9  h-8 bg-yellow-600','new idea'],
        notOnGithub:[ 'absolute top-0 left-3 w-28 h-8   transform skew-x-45  ','w-9  h-8 ',''],

    }

    const activity = () => {

        let signal
        switch (props.activity) {
            case 'mostRecent':
                signal = updated.mostRecent
                break;

            case 'newIdea':
                signal = updated.newIdea
                break;


            case 'leastRecent':
                signal = updated.leastRecent
                break;

            default: signal = updated.notOnGithub



        }
        return signal
    }
    const color1 = "#121313"
    const color2 = "#18191a"
    const color3 = "#3c3a3a"
    /*style={{background: `linear-gradient(0deg, ${color1} 0%, ${color2} 59%, ${color3} 100%)`}}*/


    return (
        <div className=' card flex flex-col  rounded-xl m-3 shadow-xl  max-w-sm ' style={{ background: `linear-gradient(0deg, ${color1} 0%, ${color2} 59%, ${color3} 100%)` }}>

            {/* <div className={activity()}></div>*/}

            <div className='flex justify-between p-1 mr-2 z-20 rounded-t-xl' style={{ background: `linear-gradient(0deg, ${color1} 0%, ${color2} 59%, ${color3} 100%)` }}>
                <label className='text-sm text-gray-500 font-bold px-2 pb-2 pt-1' >{props.Title.toUpperCase()}</label>
                <div className=' flex justify-around '>

                    <div className=' flex items-center bg-gray-800 text-white rounded-md px-1 text-sm mx-3 sm:mx-1'>
                        {(props.activity === 'notOnGithub') ? <FiGithub className='text-red-600' /> :
                            <button><FiGithub className='text-white-600'
                                onClick={() => window.open(props.githubLink, "_blank")} /></button>

                        }</div>
                    <div className=' flex items-center bg-blue-400 text-white rounded-md px-1 text-sm'>
                        <button onClick={() => window.open(props.live, "_blank")}><FiExternalLink /></button> </div>
                </div>
            </div>


            <div className='group relative '>

                <div className='flex justify-end visible group-hover:invisible transform duration ease-in-out duration-800'>

                    <div className='absolute   flex  '>
                        <div className='relative transform rotate-45 -top-6 mr-14'>
                           
                            <div className={activity()[1]}></div>
                            <div className={activity()[0]}></div>

                        </div>
                        <div className=' absolute top-2 left-3 text-white text-sm font-semibold transform rotate-45'>  &nbsp;&nbsp;&nbsp;&nbsp;{activity()[2]}</div>
                    </div>
                </div>
                <img src={props.Image} alt='projectImage' onClick={handleClick}></img>


                <div className=' ml-1 opacity-0 absolute top-2  group-hover:opacity-100 transition ease-in duration-200 transition ease-out duration-700' onClick={handleClick}>

                    <TagLabel className='grid  grid-cols-3 sm:grid-cols-2 lg:grid-cols-3 grid-rows-4 gap-2 ' tags={props.tags}></TagLabel>

                </div>

            </div>
            
            <div className='px-3 pt-2 pb-4 text-md text-gray-400' ><hr />


                <p>
                    {props.Description[0].substring(0 - 15)}... <button className='text-blue-600 text-xs underline' onClick={handleClick}>click for more!</button>
                </p>

            </div>
            <div className='flex justify-end  items-center pl-3 '>

                <span className='text-xs font-thin text-gray-500 leading-3 '>Share  this project</span>
                <ShareModule flow='flex justify-around ' size={32} shape={true} url={`https://attilawolf.info/projects/${props.url_prefix}`} shareData={`Hi, check out this font-end project: https://attilawolf.info/projects/${props.url_prefix}`} />
            </div>

        </div>
    )

}

export default ProjectCard