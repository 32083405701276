import React,{useState} from 'react'
import { ShareModule } from '../components/ShareModule'
import ContactCard from '../components/ContactCard'
import BlogCardList from '../components/BlogCardList'
import {blog} from '../assets/BlogData'
import LoginCard from '../components/LoginCard'
import LongButton from '../components/LongButton'
import { useLocation } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import {setLogged } from '../reducers/loginSlice'
import { useEffect } from 'react'
import { setServerLogin } from '../utils/comm'

const Blog =({match})=>{

    const userLogged = useSelector((state) => state.login.loggedIn)
    const currentUser = useSelector((state) => state.login.currentUser)
    const dispatch = useDispatch()


const[login,setLogin]=useState(true)
const [loggedIn, setLoggedIn]=useState(false)

const BlogData = blog.blog_data

const id = match.params.id
const currentBlog=blog.blog_data
const article=currentBlog.find(article=>article.url_prefix===id)
const location=useLocation()


useEffect(()=>{if(userLogged){setLoggedIn(true)}},[userLogged])

return(

    <div className='text-gray-300 pt-28 mb-4' style={{ backgroundColor: '#2a2a2a' }}>
        <div className='grid justify-items-center sm:flex  rounded-lg px-6 pt-3 pb-10 mb-3   ' style={{ backgroundColor: "#18191a" }}>

<div className='mx-3 w-full  sm:w-1/2' >
    <label className='text-2xl text-gray-300 font-semibold'>COGITO ERGO SCRIBO</label>
    <article className='text-sm font-thin mt-3'>
        <p>This page is currently being updated. </p>
        <p>Live chats/topic will be re initiated after update</p>
        <p>In case you liked any of the discussions or topics, why not give it a share?</p>
    </article>
    <ShareModule size={32} url={`https://attilawolf.info${location.pathname}`} shareData={`Hi, check out this Blog page: https://attilawolf.info${location.pathname}`}></ShareModule>
</div>

{loggedIn?<div className='relative w-full   h-64 sm:w-1/2' >
<label className='text-2xl mb-3  sm:mb-0 tex-gray-400 font-semibold'>
    Welcome {currentUser}!</label>  
    <LongButton onClick={()=>{setLoggedIn(false)
        dispatch(setLogged(false))
        setServerLogin(0,currentUser)} }  >Log Out</LongButton>
</div>:
<div className='relative w-full   h-64 sm:w-1/2'>


    <label className='text-2xl mb-3  sm:mb-0 tex-gray-400 font-semibold'>
    <span className='underline cursor-pointer' onClick={()=>setLogin(true)}>Login</span> / <span className='underline cursor-pointer' onClick={()=>setLogin(false)}>Sign up</span>
    
    </label>
{login?<LoginCard/>:<ContactCard signup={true}/>}
    


</div>
}

</div>
<BlogCardList blogs={BlogData}/>
    </div>
   

)


}

export default Blog