
import React,{useEffect, useState} from 'react'
import { ShareModule } from '../components/ShareModule'
import { useLocation } from "react-router-dom"
import axios from 'axios'
import {FaCommentMedical, } from 'react-icons/fa'
import{GoSmiley,}from 'react-icons/go'
import { useSelector } from 'react-redux'
import {blog} from '../assets/BlogData'
import EmojiPicker from 'emoji-picker-react';
import {getComments} from '../utils/comm'
import { Load1 } from '../components/loading'
import { useHistory } from 'react-router-dom'

const BlogPage = ({match}) => {
const currentUser = useSelector((state) => state.login.currentUser)
const userLogged = useSelector((state) => state.login.loggedIn)
const id = match.params.id
const currentBlog=blog.blog_data
const article=currentBlog.find(article=>article.url_prefix===id)
const location=useLocation()
const [comments, setComments]=useState("loading")
const history=useHistory()


const[emojiPanelOpen, setPanelOpen]=useState(false)
const onEmojiClick = (emojiObject, event) => {
 
  document.getElementById('comment').value=comment+emojiObject.emoji
  setPanelOpen(!emojiPanelOpen)
};

const[comment, setComment]=useState("")
//console.log("BlOG data ii: ",article.blog_Images[0].original)


const[blogData, setBlogData]=useState('Loading...')

useEffect(()=>{methods.getAllComments()},[])
// useEffect(()=>{console.log('IMPORT PHP METHOD DATA:',getComments("Developed","Developed"))},[])




const  methods= {
  
    getAllComments: async function () {
      const param = {
        args: {
          userUid: '',
          blogName: article.blog_Title,
        }
      }
      await axios({
        method: "POST",
        url: "/comments.php",
        data: param,
  
      }).then(function (response) {
          if (response.data.error) {
            console.log("PHP error: ",response.data.message) 
          } else {
            
            setComments(response.data)
            // console.log("COmment", response.data)
          }
        });
    },

    postComments: async function (userUid,content,blogName,postDate ) {
      const param = {
        args: {
          userUid: userUid,
          content:content,
          blogName: blogName,
          postDate:postDate
        }
      }

      await axios({
        method: "POST",
        url: "/addcomment.php",
        data: param,
  
      }).then(function (response) {
          if (response.data.error) {
            console.log("PHP error: ",response.data.message) 
          } else {
            
            // setBlogData(response.data)
            // setComments(response.data)
            console.log("posted OK")
          }
        });
    }



}

const onSubmit = (e) => {
  e.preventDefault();
console.log("submit pressed")
methods.postComments(currentUser,comment,article.blog_Title,getDate())
document.getElementById("comment").value=""
methods.getAllComments()

}

const getDate=_=>{

  let currentdate = new Date(); 
  let datetime = "commented on: " + currentdate.getDate() + "/"
                  + (currentdate.getMonth()+1)  + "/" 
                  + currentdate.getFullYear() + " @ "  
                  + currentdate.getHours() + ":"  
                  + currentdate.getMinutes() + ":" 
                  + currentdate.getSeconds();

                  return datetime

}

const handleSignup=_=>{
  history.push("/blog")
}

 //console.log("BLOG DAT",blogData, 'ARRAY:',Array.isArray(blogData),"LOC:",location.pathname)

    return (
        <div className='text-white pt-20 px-6 sm:px-16' style={{backgroundColor:'#2a2a2a'}}>

            <div id='pageContent'>

                <div id='pageHeader'>
                    <div id='projectTitle'>
    <h3 className='text-3xl text-gray-200 font-bold'>{article.blog_Title}</h3>
                        <br />
    <p className='text-md text-gray-400'>{article.blog_description}</p>
                    </div>
                    <br />
                    <div className='sm:flex  justify-between items-center' id='shareModules'>
                        <div>
                        <span className=' text-gray-400 text-xs font-thin'>Share this blog:</span>
                        <ShareModule size={52} url={`https://attilawolf.info${location.pathname}`} shareData={`Hi, check out this Blog page: https://attilawolf.info${location.pathname}`}></ShareModule>
                        </div>
                    </div>
                </div>
                <br />

                 <div className='relative  '>
                     <img width="100%" alt='header'src={article.blog_Images[0].original}></img>  
                </div>

                <br />
                <h3 className='text-3xl text-gray-200 font-bold'>Blog highlights</h3>
                 
                <p className='text-md text-yellow-300'>{article.blog_highlight_Intro}</p>
                <br/>
                 <hr />

                 <article className='text-md text-gray-400'>{article.blog_highlights}
                 <br/>
                 
                 </article>

                <br></br>
                <h3 className='text-3xl text-gray-200 font-bold'>Tags</h3>

                <br />

                <hr />
                <br />
                <ul style={{ listStyleType: 'circle' }} className='text-md text-gray-400 font-semibold'>

                    
                    {article.tags.map((tag,key)=>(
                  <li key={key}>{tag}</li>

                    ))}
                    
                </ul>


            </div>
           
    <h2 className='text-3xl text-gray-200 font-bold'>{article.blog_Title}</h2>
                        <br />
                        {/* <div className=' text-gray-400 text-xs font-thin'>ID: {blogData[0].id},Name: {blogData[0].name},<br/><span>Email:{blogData[0].email}</span>Data Type: {testData}</div> */}
            
{Array.isArray(blogData)?blogData.map((value, key) => 
<div key={key} className=' text-gray-400 text-xs font-thin'>{value.userName}</div>):
  <div>LOADING....</div>}
    
    <h2 className='text-3xl text-gray-200 font-bold'>COMMENTS</h2>
    <div className=''>
    {comments==="loading"?<Load1/>:<>{Array.isArray(comments)&&comments.map((data,key)=>(
      <div className=' text-gray-100 text-s hover:bg-gray-900 p-3 rounded-xl' key={key}><b>{data.userUid}</b><span className=' text-gray-400 text-xs'>&nbsp;{data.postDate}</span><br/> {data.content}</div>
    )
    )}</>}
    
    </div>
    {userLogged?<form
    className="flex flex-col p-3"
    onSubmit={e => onSubmit(e)}>
    <div  className="relative flex flex-row">
      
    < FaCommentMedical onClick={e => onSubmit(e)} className=' text-3xl text-gray-400 mr-2 hover:text-gray-100 cursor-pointer self-center' />
    
          <input  required={true} id='comment' placeholder={` # Comment on ${article.blog_Title}`} className=' border border-2 border-gray-200 rounded-lg mr-1 p-2 w-full bg-gray-700' type='text'
              onChange={e => setComment(e.target.value)}></input>
       <GoSmiley onClick={()=>{setPanelOpen(!emojiPanelOpen)}} className=' text-3xl text-gray-400 mr-2 hover:text-gray-100 cursor-pointer self-center'  />
        
          {emojiPanelOpen&&<div className='absolute right-0 bottom-14 border border-2 border-gray-200 rounded-lg' ><EmojiPicker onEmojiClick={onEmojiClick} height={300} width={400} theme="dark"/></div>}
    </div>
    </form> :<div className=' mb-3 text-blue-400 text-m font-thin flex flex-row'>< FaCommentMedical className=' text-3xl text-gray-400 mr-2  self-center' /><span className='self-center'><span onClick={_=>{handleSignup()}} className="cursor-pointer hover:underline"><b>Login</b></span> / <span className="cursor-pointer hover:underline"><b>Sign up</b></span> to add a comment</span></div>}
    
    
  
    </div>
    
    
    )
}

export default BlogPage