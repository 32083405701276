
import React from 'react'


const LongButton =React.forwardRef((props, ref)=>{



return(
    <>
   { props.variant==="signup"? 
    <button ref={ref}
    className='bg-green-400 hover:bg-green-500 px-3 py-1 lead-5 text-xs md:text-sm text-white font-thin w-full
    focus:outline-none focus:ring-1  focus:ring-blue-300 focus:border-transparent'
    onClick={props.onClick} type={props.type}>
        {props.children}
    </button>:props.variant==="login"? 
    <button ref={ref}
    className='bg-green-400 hover:bg-green-500 px-3 py-1 lead-5 text-xs md:text-sm text-white font-thin w-full
    focus:outline-none focus:ring-1  focus:ring-blue-300 focus:border-transparent'
    onClick={props.onClick} type={props.type}>
        {props.children}
    </button>
    :<button ref={ref}
    className='bg-blue-400 hover:bg-blue-500 px-3 py-1 lead-5 text-xs md:text-sm text-white font-thin w-full
    focus:outline-none focus:ring-1  focus:ring-blue-300 focus:border-transparent'
    onClick={props.onClick} type={props.type}>
        {props.children}
    </button>
}
</>
)

})

export default LongButton
