import React from 'react'
import ProjectCard from '../components/ProjectCard'



const ProjectCardList=(props)=>{



return(
  
   
    <div className=' grid justify-items-center  sm:grid-cols-3 grid-rows-2 gap-2 rounded-xl p-3 mt-3 '>



{props.projects.map(( project)=>(

    <div  key={project.project_Title }>
        <ProjectCard Title={project.project_Title} 
        Image={project.project_Images[0].original} 
        Description={project.project_description}
        tags={project.tags}
        githubLink={project.githubLink}
        activity={project.activity}
        live={project.live}
        url_prefix={project.url_prefix}
        />


      </div>
))}



    </div>
   
)

}

export default ProjectCardList