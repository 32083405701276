import React from 'react'





const TagLabel=(props)=>{


//console.log('tags',props.tags.tech)

return(

<div  className='flex items-center '>
    
    <div className={props.className}>

    {props.tags.map((techTags,key)=>(

<div key={key}
className=' col  bg-gray-800 text-gray-300 flex justify-center font-semibold p-2 rounded-sm text-sm  shadow-xl'
>
{techTags}

</div>

    ))}
        
    </div>


</div>

)

}

export default TagLabel