import React from 'react'
import {
    
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    EmailShareButton,
    EmailIcon,
    
  } from 'react-share';



export const ShareModule=(props)=>{

return<>
<div className={props.flow}>
    <FacebookShareButton
            url={props.url}
            quote={props.shareData}
            className="  m-1 focus:outline-none focus:ring-1 rounded-xl focus:ring-blue-600 focus:border-transparent ">
            <FacebookIcon size={props.size} round={props.shape} 
            className='border border-transparent rounded-xl hover:border-blue-300
            ' />
          </FacebookShareButton>


          <TwitterShareButton
            url={props.url}
            quote={props.shareData}
            className=" m-1 focus:outline-none focus:ring-1 rounded-xl focus:ring-blue-300 focus:border-transparent "
          >
            <TwitterIcon size={props.size} round={props.shape}  className='border border-transparent rounded-xl hover:border-blue-400'/>
          </TwitterShareButton>

        {/*  < RedditShareButton
            url={'https://twitter.com/'}
            quote={'titleThe thing you will share'}
            className=" m-1"
          >
            <RedditIcon size={props.size} round={props.shape}  className='border border-transparent rounded-xl hover:border-gray-400'/>
          </RedditShareButton>
           */}

          < EmailShareButton
            subject='Hi there'
            body={props.shareData}
            separator="&nbsp;"
            url='.'
            className=" m-1 focus:outline-none focus:ring-2 rounded-xl focus:ring-gray-300 focus:border-transparent "
          >
            <EmailIcon size={props.size} round={props.shape}  className='border border-transparent rounded-xl hover:border-gray-400'/>
          </EmailShareButton>


    </div>
    
</>


}