import React,{useState} from 'react'
import { BiCodeBlock } from "react-icons/bi";
import { SiResearchgate } from "react-icons/si";
import { FiExternalLink,} from "react-icons/fi"
import { IoIosCube} from 'react-icons/io'
import { useLocation, useHistory,Link } from "react-router-dom"


const Skills=(props)=>{

    const history = useHistory()
    const handleClick = () => {

        history.push(`/design`)

    }





return(
    <div className='  rounded-xl p-2' >



<div className='sm:flex  '>

<div className=' m-2 sm:mb-1 rounded-lg p-3 sm:w-1/3' style={{ backgroundColor: "#18191a" }}>
<div onClick={props.goProjectLoc} className=' flex font-bold text-gray-400 text-md sm:text-center items-center leading-4 hover:text-gray-300 cursor-pointer'>
    <BiCodeBlock className='text-4xl   mr-1'/>
    Front-end Developer                      
    </div>
    <hr/>
   <div className='text-gray-400'>
   <p className='my-4'> I am an aspiring developer a passionate learner and also in love with modular design.</p>
     <h2 className='text-yellow-400 text-sm text-sm'>Languages I speak:</h2>
     
       <p>HTML, CSS, SASS, Tailwind, JS</p>
       <h2 className='text-green-400 text-sm mt-4'>Developer Tools:</h2>
       <ul className='text-center'>
           <li>AWS EC2</li> 
           <li>Android Studio</li> 
           <li>Bootstrap</li>
           <li>Codepen</li>
           <li>Github</li>
           <li>NextJS</li>
           <li>Phaser</li>
           <li>React</li>
           <li>React-Redux</li>
           <li>React-Flux</li>
           <li>React-Vitejs</li>
           
           <li>Vercel</li>
       </ul>

       <h2 className='text-green-400 text-sm mt-4'>Back-End:</h2>
       <ul className='text-center'>
           <li>MongoDb</li>
           <li>Axios</li> 
           <li>Express & Passport</li> 
           
       </ul>







    </div>
</div>
<div className='  m-2 sm:mb-1  rounded-lg p-3 sm:w-1/3' style={{ backgroundColor: "#18191a" }}>
<Link to='/design' className=' flex font-bold text-gray-400 text-md sm:text-center items-center leading-4 hover:text-gray-300 cursor-pointer'>
    <IoIosCube className='text-2xl mr-1 mb-3' />
    Designer
    </Link>
    
    <hr/>
    <div className='text-gray-400'>
    <p className='my-4'> Inspired by the digital tools of Visual Aesthetics , I use computer graphics to visualize products, ideas, dreams... </p>
    <h2 className='text-yellow-400 text-sm'>My Favorite Things:</h2>
    <ul className='list-disc pl-5'><li>3D Product visualization</li> 
    <li>Designing viusal appeal for innovative technologies</li>
    <li>Motion graphics and video production</li>
    </ul>
    <h2 className='text-green-400 text-sm mt-4'>Design Tools:</h2>
    <ul className='text-center'>

           <li>Illustrator</li>
           <li>Photoshop</li>
           <li>InVision</li>
           <li>LightWave</li>
           <li>Marvelous designer</li>
           <li>Blender</li>
           
       </ul>
       <br/>
       <p >Click to see my  <span onClick={handleClick} className='text-blue-600 cursor-pointer '>design projects.</span></p>
       </div>
</div>
<div className=' m-2 sm:mb-1  rounded-lg p-3 sm:w-1/3' style={{ backgroundColor: "#18191a" }}>

<div onClick={() => window.open("https://www.researchgate.net/profile/Attila_Farkas6", "_blank")}  className= ' group flex font-bold text-gray-400 hover:text-gray-300 text-md sm:text-center items-center leading-4 cursor-pointer'>
    <SiResearchgate  className='text-2xl mr-1 mb-3 '/>
    Researcher &nbsp; < FiExternalLink className=' invisible group-hover:visible'/>
     </div>

    <hr/>
    <div className='text-gray-400'>
    <p className='my-4'>As an R&D scientist, I am well seasoned in transforming scientific methods into web and mobile applications.</p>
    <h2 className='text-purple-500 text-sm'>Patented Diagnostic Tech built for Andorid OS:</h2>
    <p onClick={() => window.open("https://www.freepatentsonline.com/y2019/0254581.html", "_blank")} className='underline text-blue-600 cursor-pointer'>
        System and Method for Diagnosing and Assessing Therapeuthic Efficacy of Mental Disorders</p>
    <h2 className='text-yellow-400 text-smtext-sm mt-4'>Experience:</h2>
    <ul className='list-disc pl-5'>

           <li>R&D scientist at <span onClick={() => window.open("https://www.sgpbusiness.com/company/Aufhaven-Pte-Ltd", "_blank")} className='underline text-blue-600 cursor-pointer text-sm'>Aufhaven PTE. LTD.</span></li>
           <li>Application Developer at <span onClick={() => window.open("https://www.janssen.com/emea/our-focus/neuroscience", "_blank")} className='underline text-blue-600 cursor-pointer text-sm'>Jannsen Pharmaceuticals</span></li>
           <li>Research associate at <span onClick={() => window.open("https://ruccs.rutgers.edu/lvr-people", "_blank")} className='underline text-blue-600 cursor-pointer text-sm'>Rutgers University</span></li>
           <li>Interaction designer and prototype developer: 
           <span onClick={() => window.open("https://ui.adsabs.harvard.edu/abs/2010iics.book..495F/abstract", "_blank")} className='underline text-blue-600 cursor-pointer text-sm'> A Proposed Treatment for Visual Field Loss caused by Traumatic Brain Injury using Interactive Visuotactile Virtual Environment</span>
           </li>
          
           
       </ul>
       </div>
</div>
</div>

    </div>
)

}

export default Skills