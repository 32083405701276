import React, { useState, useEffect, useRef } from 'react'
import {BiHomeAlt, BiMailSend, BiMenu} from 'react-icons/bi'
import { AiOutlineAppstore} from 'react-icons/ai'
import { IoIosCube, IoMdPaper} from 'react-icons/io'
import { Link,useLocation,useHistory,withRouter } from "react-router-dom"
import logo from './assets/mylogo3.png'
import $ from "jquery";


const Navbar = (props) => {

/* Toggle function also handles link logging (e.target.id) for future developens */


    const [toggle, setToggle] = useState(false)
 
    const navDrop = useRef()
     //this below to check current location of navbar and act accordingly
    const location = useLocation();
   // console.log('Location:',location.pathname);
    checkLocation()
    const history=useHistory()

   
   



    useEffect(() => {
       
       
     
        if (toggle) {
            document.addEventListener("mousedown", handleClickOutside);
          } else {
            document.removeEventListener("mousedown", handleClickOutside);
          }
      
          return () => {
            document.removeEventListener("mousedown", handleClickOutside);
          };


    },[toggle])


function checkLocation(){


if(location.pathname.includes("projects")){

}

}
    const handleClickOutside = e => {
       // console.log("clicking anywhere");
        if (navDrop.current.contains(e.target)) {
          // inside click
          return;
        }
        // outside click
        setToggle(false);
      };



    const navLinks = {

      
        showLinks: 'h-34 mt-3 sm:hidden',

        hideLinks: 'hidden sm:flex justify-start items-center pl-1 ',


    }

    



    const Toggle = (e) => {

       // console.log("nav link pressed: ", e.target.id)

        const style = window.getComputedStyle(document.querySelector('#toggler'))

        if (style.display === 'none') { } else { setToggle(!toggle) }



    }





//scroll to contact card on front page
    const handleContact=(e)=>{
    
  if(document.querySelector('#contact')){
    $(document).ready(function (){
        $(function (){
            $('html, body').animate({
                scrollTop: $("#contact").offset().top-200
            }, 800);
        });
    });
    
    
    
    }else( 
       
       handleContact2()
    )
    Toggle(e)
    }



    function handleContact2(){

        
        history.push("/")
        setTimeout(()=>{
            if(document.querySelector('#contact')){
                $(document).ready(function (){
                    $(function (){
                        $('html, body').animate({
                            scrollTop: $("#contact").offset().top-200
                        }, 800);
                    });
                });
                
                
                
                }
        },600)
        
    }

    
//scroll to project cards on front page
const handleProjects=()=>{
    
    if(document.querySelector('#projects')){
      $(document).ready(function (){
          $(function (){
              $('html, body').animate({
                  scrollTop: $("#projects").offset().top-200
              }, 800);
          });
      });
      
      
      
      }else( 
         
         handleProjects2()
      )
      Toggle()
      }
  
      function handleProjects2(){
  
          
          history.push("/")
          setTimeout(()=>{
              if(document.querySelector('#projects')){
                  $(document).ready(function (){
                      $(function (){
                          $('html, body').animate({
                              scrollTop: $("#projects").offset().top-200
                          }, 800);
                      });
                  });
                  
                  
                  
                  }
          },600)
          
      }

//scroll to top if design or blog page   
      const handleBlogDesign=(e)=>{
    
        if(document.querySelector('#motion')){
          $(document).ready(function (){
              $(function (){
                  $('html, body').animate({
                      scrollTop: $("#motion").offset().top-200
                  }, 800);
              });
          });
          
          }else(handleBlogDesign2())
          Toggle(e)
          }
      

          function handleBlogDesign2(){

        
            history.push("/design")
            setTimeout(()=>{
                if(document.querySelector('#motion')){
                    $(document).ready(function (){
                        $(function (){
                            $('html, body').animate({
                                scrollTop: $("#motion").offset().top-200
                            }, 800);
                        });
                    });
                    
                    
                    
                    }
            },600)
            
        }
  
    return (

        < div  id='navbar'>
           
            <div  className=" fixed z-40 w-full  text-white   p-3 sm:py-1" style={{ backgroundColor: '#18191a' }}>

                <div className='flex sm:hidden text-lg mr-3 '>
                    <button className=' sm:hidden text-lg mr-3 ' onClick={Toggle} id='toggler' >
                        <BiMenu className='text-4xl' />
                    </button>
                    <Link to='/' ><img alt='logo' href='https://attilawolf.info/' src={logo} className='  mx-2 w-12'></img></Link>
                    

                   {/* <div className='flex items-center justify-end'>
                        <label> <BiSearch /></label>
                        <input type='text' className='rounded-full h-1/2 w-3/4 px-3 text-gray-600'></input>
                    </div>*/}
                </div>


                <div ref={navDrop}> 


                    <ul className={toggle ? navLinks.showLinks : navLinks.hideLinks}>

                        <li>
                        <Link to='/' ><img alt='logo' href='/' src='/images/mylogo3.png' className='  mx-2 w-12'></img></Link> 
                        </li>

                        <li  className=' group flex  mx-2 my-6 sm:my-0 relative px-1 active:bg-white '
                         >

                            <div className=' absolute h-6 w-0 group-hover:w-full bg-gray-300  transform ease-in-out duration-300  '>&nbsp;</div>
                            
                            <BiHomeAlt className=' z-10 text-2xl text-gray-200 group-hover:text-black transform ease-in-out duration-300' /> <span className='group-hover:text-black  transform ease-in-out duration-300 z-10 text-gray-300'>
                                <Link  id='HomeLink' to='/' onClick={e=>{Toggle(e)}} className='focus:underline' >Home</Link>
                            </span>
                            
                        </li>

                        <li className=' group flex  mx-2 my-6 sm:my-0 relative px-1 active:bg-white' >
                            <div className=' absolute h-6 w-0 group-hover:w-full bg-gray-300  transform ease-in-out duration-300 '>&nbsp;</div>
                            
                            <AiOutlineAppstore className=' z-10 text-2xl text-gray-200 group-hover:text-black transform ease-in-out duration-300 ' /> <span className='group-hover:text-black  transform ease-in-out duration-300 z-10 text-gray-300'>
                                <Link  to='/' onClick={handleProjects} className='focus:underline'>Projects</Link>
                            </span>
                           
                        </li>


                        <li className=' group flex  mx-2 my-6 sm:my-0 relative px-1 active:bg-white' >
                            <div className=' absolute h-6  w-0 group-hover:w-full bg-gray-300  transform ease-in-out duration-300 '>&nbsp;</div>
                            
                            <IoIosCube className=' z-10 text-2xl text-gray-200 group-hover:text-black transform ease-in-out duration-300 ' /> <span className='group-hover:text-black  transform ease-in-out duration-300 z-10 text-gray-300'>
                                <Link id="DesignLink" to='/design' onClick={e=>{handleBlogDesign(e)}} className='focus:underline'>Design</Link>
                            </span>
                           
                        </li>
                        <li className=' group flex  mx-2 my-6 sm:my-0 relative px-1 active:bg-white' >
                            <div className=' absolute h-6 w-0 group-hover:w-full bg-gray-300  transform ease-in-out duration-300 '>&nbsp;</div>
                            
                            <IoMdPaper className=' z-10 text-2xl text-gray-200 group-hover:text-black transform ease-in-out duration-300 ' /> <span className='group-hover:text-black  transform ease-in-out duration-300 z-10 text-gray-300'>
                                <Link id="BlogLink" to='/blog' onClick={e=>{Toggle(e)}} className='focus:underline'>Blog</Link>
                            </span>
                           
                        </li>
                      





                        <li className=' group flex  mx-2 my-6 sm:my-0 relative px-1 active:bg-white' >
                        <div className=' absolute h-6  w-0 group-hover:w-full bg-gray-300  transform ease-in-out duration-300 '>&nbsp;</div>
                            <BiMailSend className='text-2xl text-gray-300 group-hover:text-black  
                     transform ease-in-out duration-300' /> <span className='text-gray-300 group-hover:text-black  transform ease-in-out duration-300' onClick={handleContact}>
                              <Link  id='ContactLink' to='/' onClick={e=>{handleContact(e)}} className='focus:underline'>Contact</Link>  
     </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default withRouter (Navbar)